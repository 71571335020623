import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import SEO from '../components/seo'
import { Button, Modal } from 'react-bootstrap'
import * as FeatherIcon from 'react-feather'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import qs from "query-string"
import { isMobileOnly } from 'react-device-detect'
import BaseRequestService from "../service/BaseRequestService"
import steamLogo from '../../static/images/steam-logo.png'
import epicLogo from '../../static/images/epic-logo.svg'
import uplayLogo from '../../static/images/uplay-logo.png'
import rockstarLogo from '../../static/images/rockstar_logo.png'
import googlePlay from '../../static/images/googleplay.png'
import iTunes from '../../static/images/itunes.png'
import playstation from '../../static/images/playstation.png'
import nintendo from '../../static/images/nintendo.png'
import origin from '../../static/images/origin.png'
import battleNet from '../../static/images/battlenet.png'
import xbox from '../../static/images/xbox.png'
import microsoftStore from '../../static/images/microsoft-logo.png'
import minecraftNet from '../../static/images/minecraft-logo.png'
import bethesda from '../../static/images/bethesda-logo.png'
const apiUrl = process.env.GATSBY_API_URL
const ACTIVATION = Object.freeze({
  DigitalGame: Object.freeze({
    Steam: {
      name: 'Steam',
      activeLink: 'https://store.steampowered.com/account/registerkey?key=',
      logo: <img src={steamLogo} alt="Steam" />
    },
    Epic: {
      name: 'Epic',
      activeLink: 'https://www.epicgames.com/store/en-US/redeem?code=',
      logo: <img src={epicLogo} alt="Epic" />
    },
    Uplay: {
      name: 'Uplay',
      linkType: 'instruction',
      activeLink: 'https://support.ubisoft.com/en-gb/Faqs/000025121/Activating-a-game-or-key-on-your-system#uplay',
      logo: <img src={uplayLogo} alt="Uplay" />
    },
    RockStar: {
      name: 'RockStar',
      linkType: 'instruction',
      activeLink: 'https://support.rockstargames.com/articles/360038206873/Redeeming-Rockstar-Activation-Codes-in-the-Rockstar-Games-Launcher',
      logo: <img src={rockstarLogo} alt="RockStar" />
    },
    Origin: {
      name: 'Origin',
      linkType: 'instruction',
      activeLink: 'https://help.ea.com/en/help/origin/origin/origin-code-redemption-faq',
      logo: <img src={origin} alt="Origin" />
    },
    BattleNet: {
      name: 'BattleNet',
      linkType: 'instruction',
      activeLink: 'https://us.battle.net/support/en/article/11263',
      logo: <img src={battleNet} alt="BattleNet" />
    },
    Xbox: {
      name: 'Xbox',
      activeLink: 'https://microsoft.com/redeem?code=',
      logo: <img src={xbox} alt="Xbox" />
    },
    MicrosoftStore: {
      name: 'MicrosoftStore',
      activeLink: 'https://microsoft.com/redeem?code=',
      logo: <img src={microsoftStore} alt="MicrosoftStore" />
    },
    MinecraftNet: {
      name: 'MinecraftNet',
      activeLink: 'https://www.minecraft.net/redeem?code=',
      logo: <img src={minecraftNet} alt="MinecraftNet" />
    },
    Bethesda_net: {
      name: 'Bethesda_net',
      linkType: 'instruction',
      activeLink: 'https://help.bethesda.net/app/answers/detail/a_id/34274/kw/redeem',
      logo: <img src={bethesda} alt="Bethesda_net" />
    }
  }),
  DigitalTopUpCard: Object.freeze({
    Steam: {
      name: 'Steam',
      activeLink: 'https://store.steampowered.com/account/redeemwalletcode?code=',
      logo: <img src={steamLogo} alt="Steam" />
    },
    GooglePlay: {
      name: 'GooglePlay',
      activeLink: 'https://play.google.com/store?code=',
      logo: <img src={googlePlay} alt="GooglePlay" />
    },
    Playstation: {
      name: 'Playstation',
      linkType: 'instruction',
      activeLink: 'https://www.playstation.com/en-us/explore/playstationnetwork/redemption',
      logo: <img src={playstation} alt="Playstation" />
    },
    Nintendo: {
      name: 'Nintendo',
      linkType: 'instruction',
      activeLink: 'https://en-americas-support.nintendo.com/app/answers/detail/a_id/16906/~/how-to-redeem-a-nintendo-eshop-download-code-online',
      logo: <img src={nintendo} alt="Nintendo" />
    },
    iTunes: {
      name: 'iTunes',
      linkType: 'instruction',
      activeLink: 'https://support.apple.com/en-sg/HT201209',
      logo: <img src={iTunes} alt="iTunes" />
    },
    Xbox: {
      name: 'Xbox',
      activeLink: 'https://microsoft.com/redeem?code=',
      logo: <img src={xbox} alt="Xbox" />
    },
    MicrosoftStore: {
      name: 'MicrosoftStore',
      activeLink: 'https://microsoft.com/redeem?code=',
      logo: <img src={microsoftStore} alt="MicrosoftStore" />
    }
  })
})

class RedeemPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      fetchedData: [],
      user: '',
      keyData: [],
      receiptData: [],
      shownKey: false,
      token: this.getToken(),
      receipt: {
        showReceiptModal: false,
        itemName: '',
        receiptContent: ''
      },
      subscribeNewsletter: null,
      showSubscribePopup: false,
      isSubscribedNewsletter: false,
      bottomSubscribePopup: isMobileOnly ? 0 : 20
    }
    this.requestService = new BaseRequestService();
  }

  async componentDidMount() {
    if (this.state.token) {
      await this.getOrderItems()
      this.getSubscribeNewsletter()
      !isMobileOnly && window.addEventListener('scroll', this.handlePositionPopup);
    }
  }

  componentWillUnmount() {
    !isMobileOnly && window.removeEventListener('scroll', this.handlePositionPopup);
  }

  getKey = async () => {
    const token = this.state.token
    if (!token) {
      console.warn('Missing Token')
    }
    await fetch(`${apiUrl}/key/${token}`)
      .then(response => {
        return response.json()
      })
      .then(response => {
        const keyData = {}
        const receiptData = {}
        response.keys &&
          response.keys.forEach(keyItem => {
            keyItem.keys &&
              keyItem.keys.forEach((key, idx) => {
                keyData[`${keyItem.orderItemId}-${idx}`] = key
              })
          })
        response.receipts &&
          response.receipts.forEach(receiptItem => {
            receiptItem.receipts &&
              receiptItem.receipts.forEach((receipt, idx) => {
                receiptData[`${receiptItem.orderItemId}-${idx}`] = receipt
              })
          })
        this.setState({
          keyData: keyData,
          receiptData: receiptData,
          shownKey: true
        })
      })
  }

  getOrderItems = async () => {
    console.log(`Get order items`)
    const token = this.state.token
    if (!token) {
      console.warn('Missing Token')
    }
    await fetch(`${apiUrl}/order/${token}`)
      .then(response => {
        return response.json()
      })
      .then(data => {
        if (data.order) {
          if (data.order.redeemedTime !== null) {
            this.getKey()
          }
          this.setState({
            fetchedData: data.order.orderItems,
            user: data.order.user,
            loading: false
          })
        } else {
          this.setState({
            loading: false,
            token: ''
          })
        }
      })
  }

  getToken() {
    const { location } = this.props
    const search = this.props.location.search;
    const paramsSearch = qs.parse(search);
    let token = paramsSearch.t;
    if (!token) {
      const params = location.pathname.split('/')
      token = params[2];
    }
    return token;
  }

  onCopyKey = (itemId) => {
    const copiedEls = document.getElementsByClassName('item-' + itemId)
    if (copiedEls.length) {
      const copiedEl = copiedEls[0]
      copiedEl.classList.add('copied')
      setTimeout(() => {
        copiedEl.classList.remove('copied')
      }, 500)
    }
  }

  showReceipt = (itemName, receipt) => {
    // Fix issue auto scroll to top when close dialog
    document.body.classList.add('modal-trick');

    this.setState({
      receipt: {
        showReceiptModal: true,
        receiptContent: receipt ? receipt.trim() : '',
        itemName: itemName
      }
    })
  }

  onHideReceiptModal = () => {
    setTimeout(() => {
      document.body.classList.remove('modal-trick');
    }, 300);
    this.setState({
      receipt: {
        showReceiptModal: false
      }
    })
  }

  getSubscribeNewsletter = () => {
    const token = this.state.token
    this.requestService.get(`/subscribe-email/${token}`).then((response) => {
      if (response) {
        this.setState({
          subscribeNewsletter: response.data || {}
        })
        setTimeout(() => {
          this.setState({
            showSubscribePopup: true
          })
          this.handlePositionPopup();
        }, 1000)
      }
    });
  }

  onSubscribeNewsletter = () => {
    this.setState({
      isSubscribedNewsletter: true
    })
    setTimeout(() => {
      this.setState({
        showSubscribePopup: false
      })
    }, 1200)

    const data = {
      redemptionToken: this.state.token,
      isConsent: true
    }
    this.requestService.post('/subscribe-email', data).then((response) => {
      if (response && response.data) {
        this.setState({
          subscribeNewsletter: response.data || {}
        })
      }
    });
  }

  notSubscribeNewsletter = () => {
    this.setState({
      showSubscribePopup: false
    })
    const token = this.state.token
    const data = {
      redemptionToken: token,
      isConsent: false
    }
    this.requestService.post('/subscribe-email', data).then((response) => {
      if (response && response.data) {
        this.setState({
          subscribeNewsletter: response.data || {},
          isSubscribedNewsletter: true
        })
      }
    });
  }

  handlePositionPopup = _.debounce((event) => {
    if (isMobileOnly) {
      return
    }
    const getDocHeight = () => {
      const D = document;
      return Math.max(
        D.body.scrollHeight, D.documentElement.scrollHeight,
        D.body.offsetHeight, D.documentElement.offsetHeight,
        D.body.clientHeight, D.documentElement.clientHeight
      );
    }
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = getDocHeight();
    const offsetBottom = documentHeight - scrollTop - windowHeight;
    let bottomSubscribePopup = 20
    if (offsetBottom < 100) {
      bottomSubscribePopup = 120 - offsetBottom
    }
    this.setState({
      bottomSubscribePopup
    })
  }, 100)

  render() {
    const { fetchedData, user, keyData, receiptData, loading, shownKey, token, subscribeNewsletter, showSubscribePopup, isSubscribedNewsletter, bottomSubscribePopup } = this.state
    const addPopupSubscribe = subscribeNewsletter && !subscribeNewsletter.isConsent && !subscribeNewsletter.isTheSameUrl && !isSubscribedNewsletter

    if (!token) {
      return (
        <Layout>
          <Helmet>
            <meta name="description" content="Token is missing or expired" />
            <title>Token is missing or expired :: Toy Or Game</title>
          </Helmet>
          <div className="pt-5">
            <h3 className="text-center text-black-50 mt-0">
              <FeatherIcon.AlertCircle /> Token is missing or expired!
            </h3>
          </div>
        </Layout>
      )
    }
    const preLoader = (
      <div className="preloader">
        <div className="status">
          <div className="spinner-border avatar-sm text-black-50 m-2" role="status" />
        </div>
      </div>
    )
    return (
      <Layout>
        {loading && preLoader}
        <SEO lang="en" title={this.props.data.site.siteMetadata.title + ' :: Toy Or Game'} />
        <div className="redeem">
          <div className="pb-3">
            <h3 className="welcome-title mt-0">Thank you for your purchase!</h3>
          </div>
          <div className="d-flex align-items-center justify-content-between flex-column flex-md-row">
            <div className="d-flex align-items-center w-100 pb-3 pb-sm-0">
              <div className="email-icon">
                <FeatherIcon.Mail />
              </div>
              <h5 className="ml-3 font-weight-light">{user}</h5>
            </div>
            <Button variant="danger" className="flex-shrink-0 btn-redeem" onClick={this.getKey} disabled={shownKey}>
              REDEEM CODE
            </Button>
          </div>
          {addPopupSubscribe && (
            <div className={"popup-subscribe-email " + (showSubscribePopup ? 'show' : '')} style={showSubscribePopup ? {bottom: bottomSubscribePopup} : null}>
              <span className="btn-close" onClick={this.notSubscribeNewsletter}>
                <FeatherIcon.X />
              </span>
              <div className="title">Be the first to hear the deals!</div>
              <div className="popup-content">
                <p>Subscribe us to get information about promotions, products and other updates sooner.</p>
                <p>TOG values your privacy and you may unsubscribe at any time.</p>
              </div>
              <div className="popup-footer text-center">
                <Button variant="danger" onClick={this.onSubscribeNewsletter}>
                  Subscribe
                </Button>
              </div>
            </div>
          )}
          {isSubscribedNewsletter && (
            <div className={"popup-subscribe-email text-center " + (showSubscribePopup ? 'show' : '')} style={showSubscribePopup ? {bottom: bottomSubscribePopup} : null}>
              <div className="text-success mt-3">
                <FeatherIcon.CheckCircle size={60} />
              </div>
              <div className="title">Thank you!</div>
              <p>Thank you for subscribing to our newsletter.</p>
            </div>
          )}
          {fetchedData.map(item => (
            <div className="card-product" key={item.id}>
              <div className="card-product-img">
                <img src={item.image} alt="Game" />
              </div>
              <div className="card-product-infos">
                <div className="product-info">
                  <div className="product-name">{item.name}</div>
                  <div className="activation-logo">{ACTIVATION[item.type] && ACTIVATION[item.type][item.activation] && ACTIVATION[item.type][item.activation].logo}</div>
                </div>
                <div className="key-info">
                  <CopyToClipboard text={keyData[item.id]} onCopy={() => this.onCopyKey(item.id)}>
                    <div className={'key-data item-' + item.id + (keyData[item.id] ? ' show-key' : '')}>
                      {keyData[item.id]}
                    </div>
                  </CopyToClipboard>

                  {ACTIVATION[item.type] && ACTIVATION[item.type][item.activation] && (
                    <div className="active-button-wrapper">
                      {shownKey &&
                        (ACTIVATION[item.type][item.activation].linkType === 'instruction' ? (
                          <a href={ACTIVATION[item.type][item.activation].activeLink} target="_blank">
                            <Button variant="danger" className="btn-redeem">
                              INSTRUCTION
                            </Button>
                          </a>
                        ) : (
                          <a href={ACTIVATION[item.type][item.activation].activeLink + keyData[item.id]} target="_blank">
                            <Button variant="danger" className="btn-redeem">
                              ACTIVATE
                            </Button>
                          </a>
                        ))}
                      {!shownKey && (
                        <Button variant="danger" className="btn-redeem" disabled={true}>
                          {ACTIVATION[item.type][item.activation].linkType !== 'instruction' ? 'ACTIVATE' : 'INSTRUCTION'}
                        </Button>
                      )}
                    </div>
                  )}
                </div>
                <div className="btn-receipt">
                  {receiptData[item.id] && (
                    <span className="cursor-pointer text-primary" onClick={() => this.showReceipt(item.name, receiptData[item.id])}>More information</span>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Modal
          dialogClassName="receipt-modal"
          size="md"
          centered
          show={this.state.receipt.showReceiptModal}
          onHide={this.onHideReceiptModal}
          scrollable
        >
          <Modal.Header>
            <Modal.Title>
              {this.state.receipt.itemName}
            </Modal.Title>
            <div className="btn-close-modal" onClick={this.onHideReceiptModal}>
                <FeatherIcon.X size={20} />
              </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              {this.state.receipt.receiptContent}
            </div>
          </Modal.Body>
        </Modal>
      </Layout>
    )
  }
}

export default RedeemPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
        capitalizeTitleOnHome
        titleImage
      }
    }
  }
`
