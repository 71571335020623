import axios from 'axios';

class BaseRequestService {
  constructor() {
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);

    axios.defaults.baseURL = process.env.GATSBY_API_URL;
    const instance = axios.create();
    instance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.instance = instance;
  }

  handleSuccess(response) {
    return response;
  }

  handleError(error) {
    console.log(JSON.stringify(error, null, 2));
    if (error.response) {
      return Promise.reject({ ...error.response.data, code: error.response.status });
    } else {
      return Promise.reject(error);
    }
  }

  get(url, config = {}) {
    return this.instance.get(url, config).catch((error) => {
      console.log('Have an error when perform get ' + url);
      return this.handleError(error);
    });
  }

  post(url, body, config = {}) {
    return this.instance.post(url, body, config).catch((error) => {
      console.log('Have an error when perform post ' + url);
      return this.handleError(error);
    });
  }

  put(url, body, config = {}) {
    return this.instance.put(url, body, config).catch((error) => {
      console.log('Have an error when perform put ' + url);
      return this.handleError(error);
    });
  }

  delete(url, config = {}) {
    return this.instance.delete(url, config).catch((error) => {
      console.log('Have an error when perform delete ' + url);
      return this.handleError(error);
    });
  }
}

export default BaseRequestService;
